import React from "react";
import {useSpring, animated} from 'react-spring'
import Typewriter from "typewriter-effect";
import logo from "../../assets/images/ic_logo.svg"
import mouse_logo from "../../assets/images/ic_mouse.svg"

import "../../assets/styles/HeroComponent.scss";

const HeroComponent = ({
  navTitle,
  navSubtitle,
  navContact,
  heroTitles,
  heroSubtitle,
}) => {
  const props = useSpring({opacity: 1, from: {opacity: 0}})
  return (
    <animated.div style={props} className="hero">
      <div className="content">
        <div className="nav">
          <div className="name">
              <img src={logo} alt="Castor Logo"/>
              {/* <div className="title-container">
                <div className="title">{navTitle}</div>
                <div className="subTitle">{navSubtitle}</div>
              </div> */}
          </div>
          <div className="contact-container">
            <a className="contact" href="mailto:info@CETAC.com"  target="_blank" rel="noopener noreferrer">
              {navContact}
            </a>
          </div>
        </div>
        <div className="text-container">
          <div className="subtitle">
          {heroSubtitle}
            </div>
          <Typewriter
            options={{
              strings: heroTitles,
              autoStart: true,
              pauseFor: 2000,
              loop: true,
              cursor: "_",
              wrapperClassName:"title",
              cursorClassName:'cursor',
            }}
          />
        </div>
          <div className="contact-container-mobile">
            <a className="contact-btn" href="mailto:info@CETAC.com"  target="_blank" rel="noopener noreferrer">
              {navContact}
            </a>
          </div>
        <img className="mouse-icon" src={mouse_logo} alt="mouse" />
      </div>
    </animated.div>
  );
};

export default HeroComponent;
