/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./../../assets/styles/Footer.scss"
import LinkedInIcon from "../../assets/images/ic_linkedin.svg";
const Footer = ({copyright, policy, termsConditions}) => {
    return (
        <div className="footer">
            <div className="inner">
            <div className="copyright">
                {copyright}
            </div>
            <div className="links">
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                    <img src={LinkedInIcon} alt="linkedin" />
                </a>
            </div>
            </div>
            
        </div>
    )
}

export default Footer;