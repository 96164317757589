import React from "react";
import PurposeIllustration from "../../assets/images/purpose.png";

import "../../assets/styles/MissionStatementSection.scss";
import SlideVisibilityAnimation from '../../components/SlideVisibilityAnimation';

const MissionStatementSection = ({ title, paragraphOne, paragraphTwo, paragraphThree }) => {
  return (
    <div className="mission">
      <div className="content">
        <div>
          <div className="title">{title}</div>
          <div className="body">
            {paragraphOne}
            <br /><br />
            {paragraphTwo}
            <br /><br />
            {paragraphThree}
          </div>
        </div>
        <SlideVisibilityAnimation>
        <div className="illustration">
            <div className="illustration-bg"/>
            <img src={PurposeIllustration} alt="illustration"/>
          </div>
          </SlideVisibilityAnimation>
      </div>
    </div>
  );
};

export default MissionStatementSection;
