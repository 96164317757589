/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState }  from 'react'

import {useVisible} from 'react-hooks-visible';
import {useSpring, animated} from 'react-spring'

const VisibilityAnimation = ({children, threshold}) => {
    const [targetRef, visible] = useVisible();
    const [toggle, setToggle] = useState(false);
    useEffect(()=>{
        if (visible > threshold) {
            setToggle(true);
          }
    }, [visible]);
    const props = useSpring({opacity: toggle ? 1:0, from: {opacity: 0}})
    return (
        <animated.div ref={targetRef} style={props}>{children}</animated.div>
    )
}
VisibilityAnimation.defaultProps = {
    threshold: 0.4
  };
export default VisibilityAnimation
