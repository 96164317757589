import React, { useState } from "react";
import "./../../assets/styles/ContactSection.scss";

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const ContactSection = ({
  title,
  info,
  addrLineOne,
  addrLineTwo,
  addrLineThree,
  emailTitle,
  emailAddress,
  tickerTitle,
  tickerValue,
  formNameHint,
  formEmailHint,
  formMessageHint,
  formSubmit,
  showExtra=true,
}) => {
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [message, setMessage] = useState(false);
  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...{name,email,message} })
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error));

    e.preventDefault();
  };
  return (
    <div className="contact">
      <div className="circle" />
      <div className="contact-inner">
        <div className="content">
          <div className="title">{title}</div>
          <div className="info" style={{maxWidth:420}}>{info}</div>
          <div className="address-line">{addrLineOne}</div>
          <div className="address-line">{addrLineTwo}</div>
          <div className="address-line">{addrLineThree}</div>
          {showExtra&&<div className="extra">
            <div className="item">
              <div className="title-small">{emailTitle}</div>
              <div className="address-line">{emailAddress}</div>
            </div>
            <div className="item">
              <div className="title-small">{tickerTitle}</div>
              <div className="address-line">{tickerValue}</div>
            </div>
          </div>}
        </div>
        <div className="form">
          <form onSubmit={handleSubmit} action="/" name="contact" method="POST" data-netlify="true">
            <div className="form-row">
              <input
                required
                style={{ marginRight: 20 }}
                type="text"
                name="name"
                placeholder={formNameHint}
                onChange={(e)=>setName(e.target.value)}
              />
              <input
                required
                style={{ marginLeft: 20 }}
                type="text"
                name="email"
                placeholder={formEmailHint}
                onChange={(e)=>setEmail(e.target.value)}
              />
            </div>
            <div className="form-row">
              <textarea 
                onChange={(e)=>setMessage(e.target.value)}
                placeholder={formMessageHint} 
                rows="10" 
                name="message"/>
            </div>
            <div className="form-submit-row">
              <button type="submit">{formSubmit}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
