import React from "react";
import AboutIllustration from "../../assets/images/about.png";
import "../../assets/styles/AboutSection.scss";
import SlideVisibilityAnimation from '../../components/SlideVisibilityAnimation';

const AboutSection = ({ title, paragraphOne, paragraphTwo }) => {
  return (
    <div className="gray-wrapper">
        <div className="about">
        <div className="content">
          <SlideVisibilityAnimation direction="left">
            <div className="illustration">
              <div className="illustration-bg" />
              <img src={AboutIllustration} alt="illustration" />
            </div>
            </SlideVisibilityAnimation>
            <div>
              <div className="title">{title}</div>
              <div className="body">
                  {paragraphOne}
                  <br />
                  <br />
                  {paragraphTwo}
              </div>
            </div>
        </div>
        </div>
    </div>
  );
};

export default AboutSection;
