import React from "react";
import LocalizedStrings from "react-localization";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import HeroComponent from "./HeroComponent";
import MissionStatementSection from "./MissionStatementSection";
// import TeamSection from "./TeamSection";

import "./Home.scss";
import Footer from "./Footer";
import VisibilityAnimation from '../../components/VisibilityAnimation';

const strings = new LocalizedStrings({
  en: {
    navTitle: "Castor Emergency Technology",
    navSubtitle: "Acquisition Corporation",
    navContact: "Contact us",
    heroTitles: ["Everything", "Markets", "Trade", "Payments", "Banking", "Money"],
    heroSubtitle: "Technology is changing",
    missionTitle: "Our purpose",
    missionParagraph1:
      "Future of Finance Acquisition Corporation (FOFAC) is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the financial technology sector, including blockchain, cryptocurrency, and fintech.",
    missionParagraph2:
      "While we may pursue initial business combination targets in any business or industry, we intend to target businesses building revolutionary and scalable technologies within the fintech sector that will play an important role in the future of the digital economy.",
    missionParagraph3:
      "We are seeking companies with enterprise values of $75mm to $300mm, scalable business models, and strong management teams. By partnering with FOFAC and leveraging our experience building leading fintech organizations, together, we will help build the future of finance.",
    aboutTitle: "About us",
    aboutParagraph1:
      "We are a team of seasoned operators and capital markets professionals with experience in the blockchain, cryptocurrency, and fintech industries, as well as experience executing and structuring M&A deals across Canada and internationally. We're passionate about the future of finance, markets and money, and collectively have access to some of the best companies and investors in these industries.",
    aboutParagraph2:
      "",
    teamTitle: "Our leadership team",
    teamMembers: [
      {
        name: "Chris Spoke",
        role: "Venture Capitalist",
        linkedInUrl: "https://linkedin.com",
        profileImageUrl: "assets/images/jeff.webp",
        proCareerTitle: "Professional Career",
        proCareerParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
        experienceTitle: "Board Experience",
        experienceParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
      },
      {
        name: "Matt Spoke",
        role: "Venture Capitalist",
        linkedInUrl: "https://linkedin.com",
        profileImageUrl: "assets/images/jeff.webp",
        proCareerTitle: "Professional Career",
        proCareerParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
        experienceTitle: "Board Experience",
        experienceParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
      },
      {
        name: "Jeff Tchadjeu",
        role: "Venture Capitalist",
        linkedInUrl: "https://linkedin.com",
        profileImageUrl: "assets/images/jeff.webp",
        proCareerTitle: "Professional Career",
        proCareerParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
        experienceTitle: "Board Experience",
        experienceParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
      },
      {
        name: "Jon Truong",
        role: "Venture Capitalist",
        linkedInUrl: "https://linkedin.com",
        profileImageUrl: "assets/images/jeff.webp",
        proCareerTitle: "Professional Career",
        proCareerParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
        experienceTitle: "Board Experience",
        experienceParagraph:
          "Castor Emerging Technology Acquisition Corp. is a blank-check company formed to unlock shareholder value by identifying an acquisition target in the emerging technology sector, including consumer tech, SaaS, fintech, healthtech, cryptocurrency, and others.",
      },
    ],
    contactTitle: "Get in touch",
    contactText: "For all inquiries, please get in touch using this form or by email at {0}.",
    contactEmail: "info@fofac.co",
    contactLine1: "550 Riverfront Ave SE, Suite 505",
    contactLine2: "Calgary, AB",
    contactLine3: "Canada, T2G 1E5",
    contactTickerTitle: "Ticker",
    contactEmailTitle: "Email",
    contactTicker: "TSX:FOF",
    formNameHint: "Full name",
    formEmailHint: "Email address",
    formMessageHint: "Your message",
    formSubmit: "Submit",
    termsRights:
      "© 2021 Future of Finance Acquisition Corporation",
    termsPolicy: "Privacy Policy",
    termsConditions: "Terms and Conditions",
  },
});

const HomePage = () => {
  return (
    <div className="Home">
      <HeroComponent
        navTitle={strings.navTitle}
        navSubtitle={strings.navSubtitle}
        navContact={strings.navContact}
        heroTitles={strings.heroTitles}
        heroSubtitle={strings.heroSubtitle}
      />
      <VisibilityAnimation>
        <MissionStatementSection
          title={strings.missionTitle}
          paragraphOne={strings.missionParagraph1}
          paragraphTwo={strings.missionParagraph2}
          paragraphThree={strings.missionParagraph3}
        />
      </VisibilityAnimation>
      <VisibilityAnimation>
        <AboutSection
          title={strings.aboutTitle}
          paragraphOne={strings.aboutParagraph1}
          paragraphTwo={strings.aboutParagraph2}
        />
      </VisibilityAnimation>
      {/* HIDING OUR LEADERSHIP section for now */}
      {/* <VisibilityAnimation threshold={0.05}>
        <TeamSection title={strings.teamTitle} members={strings.teamMembers} />
      </VisibilityAnimation> */}
      <VisibilityAnimation>
        <ContactSection
          title={strings.contactTitle}
          info={strings.formatString(
            strings.contactText,
            <a href={`mailto:${strings.contactEmail}`}>{strings.contactEmail}</a>
          )}
          addrLineOne={strings.contactLine1}
          addrLineTwo={strings.contactLine2}
          addrLineThree={strings.contactLine3}
          emailTitle={strings.contactEmailTitle}
          email={strings.contactEmail}
          tickerTitle={strings.contactTickerTitle}
          tickerValue={strings.contactTicker}
          formNameHint={strings.formNameHint}
          formEmailHint={strings.formEmailHint}
          formMessageHint={strings.formMessageHint}
          formSubmit={strings.formSubmit}
          showExtra={false}
        />
      </VisibilityAnimation>
      <Footer
        copyright={strings.termsRights}
        policy={strings.termsPolicy}
        termsConditions={strings.termsConditions}
      />
    </div>
  );
};

export default HomePage;
