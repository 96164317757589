/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useVisible } from "react-hooks-visible";
import { useSpring, animated } from "react-spring";

const SlideVisibilityAnimation = ({ children, direction, threshold }) => {
  let initialOffest = direction === "left" ? -100 : 100;
  const condition = (v) => {
    if (direction === "left") {
      return v > threshold
        ? "translateX(0px)"
        : `translateX(${initialOffest}px)`;
    } else {
      return v > threshold
        ? "translateX(0px)"
        : `translateX(${initialOffest}px)`;
    }
  };
  const [targetRef, visible] = useVisible();
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (visible > threshold) {
      setToggle(true);
    }
  }, [visible]);
  const props = useSpring({
    transform: toggle ? "translateX(0px)" : condition(visible),
    opacity: toggle ? 1 : 0,
    from: { opacity: 0, transform: "translateX(0px)" },
  });
  return (
    <animated.div ref={targetRef} style={props}>
      {children}
    </animated.div>
  );
};
SlideVisibilityAnimation.defaultProps = {
    threshold: 0.5
  };
export default SlideVisibilityAnimation;
